import type { PageSection } from '@/types/graphql';
import { cloneDeepObject } from '@/utils/common';
import useSectionStore from '../../common/stores/section';
import type { ActionSettingInput } from '../../common/use-cases/component';
import { componentUpdateSetting } from '../../common/use-cases/component';
import { sentryCaptureException } from '../../common/use-cases/sentry';
import {
  convertComponentToJSON,
  getComponentByUid,
  updateControlValueInComponent,
} from '../../common/utils/section/component';
import type { ActionOptions, GroupTypeSetting } from '../../common/utils/types';
import { builderUpdateComponent } from '../../preview/utils/builder';
import useEditorStore from '../../common/stores/editor';
import useNotification from '@/composables/useNotification';
import { getConfig } from '@/config';

export type ChangeSettingsInput = {
  sectionCid: string;
  settings: ActionSettingInput[];
  options?: ActionOptions;
  customizeOldData?: string;
};

const { handleSuccess } = useNotification();

const checkIsTestMode = () => {
  const isDevMode = getConfig('configEnv') === 'development';
  const editorStore = useEditorStore();
  return editorStore.enableTestMode === 'on' && isDevMode;
};

export const controlOnChange = ({ sectionCid, settings }: ChangeSettingsInput) => {
  if (checkIsTestMode()) {
    handleSuccess('controlOnChange');
  }
  const sectionStore = useSectionStore();
  const section = sectionStore.getItemByCid(sectionCid);
  // Clone section
  const sectionClone = cloneDeepObject(section) as PageSection;
  if (!sectionClone.component) {
    sentryCaptureException('controlOnChange', 'component empty', sectionClone, {
      level: 'warning',
    });
    return;
  }

  // Create new component
  const newComponent = updateControlValueInComponent({
    component: sectionClone.component,
    settings,
  });

  // Apply to preview
  settings.forEach((setting) => {
    const jsonComponent = convertComponentToJSON(newComponent);
    if (jsonComponent) {
      const currentComponent = getComponentByUid(jsonComponent, setting.componentUid);
      if (currentComponent) {
        const data: Record<GroupTypeSetting, any> = {
          advanced: currentComponent.advanced,
          setting: currentComponent.settings,
          style: currentComponent.styles,
        };

        const newValue = data?.[setting.groupType]?.[setting.controlId];
        builderUpdateComponent({
          uid: setting.componentUid,
          propName: setting.controlId,
          propValue: newValue,
          group: setting.groupType,
        });
      }
    }
  });
};

export const controlChange = ({ sectionCid, settings, options, customizeOldData }: ChangeSettingsInput) => {
  if (checkIsTestMode()) {
    !options?.noRecordHistory && handleSuccess('controlChange');
  }
  const sectionStore = useSectionStore();
  const section = sectionStore.getItemByCid(sectionCid);
  if (section) {
    const clone = cloneDeepObject(section);
    if (clone?.component) {
      // Update control value
      const newComponent = updateControlValueInComponent({
        component: clone.component,
        settings,
      });

      componentUpdateSetting({
        cid: sectionCid,
        component: newComponent,
        settings,
        options,
        customizeOldData,
      });
    }
  }
};
