import { type Control, type GroupTypeSetting } from '@gem/common';
import type { SettingUIGroup } from '@gem/control-v2';
import { ANIMATION_DEFAULT_CONFIG } from '../../settings/components/controls/animation/constant';

export const advancedSettingV2: { id: GroupTypeSetting; controls: Control[] } = {
  id: 'advanced',
  controls: [
    {
      id: 'd',
      type: 'visibility',
      hideOnMode: {
        mobileOnly: true,
      },
      devices: {
        desktop: {
          default: true,
        },
        tablet: {
          default: true,
        },
        mobile: {
          default: true,
        },
      },
    },
    {
      id: 'spacing-setting',
      type: 'spacing',
      devices: {
        desktop: {},
      },
    },
    {
      id: 'border',
      type: 'border-v2',
      devices: {
        desktop: {
          default: {
            normal: {
              borderType: 'none',
              border: 'none',
              borderWidth: '1px',
              width: '1px 1px 1px 1px',
              color: '#121212',
              isCustom: true,
            },
          },
        },
      },
      compoDefaultValue: {
        desktop: {
          default: {
            normal: {
              border: 'solid',
              color: '#212121',
              width: '1px',
              borderWidth: '1px',
            },
          },
        },
      },
    },
    {
      id: 'rounded',
      type: 'corner-v2',
      devices: {
        desktop: {
          default: {
            normal: {
              btrr: '0px',
              bblr: '0px',
              bbrr: '0px',
              btlr: '0px',
              radiusType: 'none',
            },
          },
        },
      },
      compoDefaultValue: {
        desktop: {
          default: {
            normal: {
              btrr: '8px',
              bblr: '8px',
              bbrr: '8px',
              btlr: '8px',
              radiusType: 'rounded',
            },
          },
        },
      },
    },
    {
      id: 'boxShadow',
      type: 'shadow-v2',
      devices: {
        desktop: {
          default: {
            normal: {},
          },
        },
      },
      compoDefaultValue: {
        desktop: {
          default: {
            normal: {
              type: 'shadow-1',
              distance: '4px',
              blur: '4px',
              spread: '0px',
              color: '#121212',
              angle: 45,
            },
          },
        },
      },
    },
    {
      id: 'op',
      type: 'range',
      min: 0,
      max: 100,
      step: 1,
      units: ['%'],
      devices: {
        desktop: {
          default: '100%',
        },
        tablet: {},
        mobile: {},
      },
    },
    {
      id: 'pos',
      type: 'select',
      label: 'Position',
      option: {
        label: 'large',
      },
      options: [
        {
          value: 'static',
          label: 'Static',
        },
        {
          value: 'relative',
          label: 'Relative',
        },
        {
          value: 'absolute',
          label: 'Absolute',
        },
        {
          value: 'sticky',
          label: 'Sticky',
        },
        {
          value: 'fixed',
          label: 'Fixed',
        },
      ],
      devices: {
        desktop: 'static',
        tablet: 'static',
        mobile: 'static',
      },
    },
    {
      id: 'top',
      label: 'Top',
      option: {
        label: 'large',
      },
      type: 'input:unit',
      units: ['px', '%'],
      emptyOnClear: true,
      devices: {
        desktop: '0%',
        tablet: '0%',
        mobile: '0%',
      },
    },
    {
      id: 'right',
      label: 'Right',
      type: 'input:unit',
      units: ['px', '%'],
      emptyOnClear: true,
      devices: {
        desktop: '0%',
        tablet: '0%',
        mobile: '0%',
      },
    },
    {
      id: 'bottom',
      label: 'Bottom',
      type: 'input:unit',
      units: ['px', '%'],
      emptyOnClear: true,
      devices: {
        desktop: '0%',
        tablet: '0%',
        mobile: '0%',
      },
    },
    {
      id: 'left',
      label: 'Left',
      type: 'input:unit',
      units: ['px', '%'],
      emptyOnClear: true,
      devices: {
        desktop: '0%',
        tablet: '0%',
        mobile: '0%',
      },
    },
    {
      id: 'z',
      label: 'Z-Index',
      type: 'input:number',
      devices: {
        desktop: 0,
        tablet: 0,
        mobile: 0,
      },
    },
    {
      id: 'editorData',
      type: 'customCodeEditor',
      rootClassName: '{{rootClassName}}',
    },
    {
      id: 'cssClass',
      label: 'CSS class',
      placeholder: 'Enter multiple class names separated by spaces.',
      option: {
        label: 'large',
      },
      type: 'textarea',
    },
    {
      id: 'animation',
      type: 'animation',
      option: {
        label: 'large',
      },
      devices: {
        desktop: {
          default: ANIMATION_DEFAULT_CONFIG,
        },
      },
    },
  ],
};

export const advancedSettingUIV2: SettingUIGroup[] = [
  {
    label: {
      en: 'Display on',
    },
    controls: [
      {
        layout: 'vertical',
        setting: {
          id: 'd',
        },
      },
    ],
  },
  {
    label: {
      en: 'Spacing (px)',
    },
    controls: [
      {
        layout: 'vertical',
        setting: {
          id: 'spacing-setting',
        },
      },
    ],
  },
  {
    label: {
      en: 'Shape',
    },
    controls: [
      {
        label: {
          en: 'Border',
        },
        setting: {
          id: 'border',
          state: 'normal',
        },
      },
      {
        label: {
          en: 'Corner',
        },
        setting: {
          id: 'rounded',
          state: 'normal',
        },
      },
      {
        label: {
          en: 'Shadow',
        },
        setting: {
          id: 'boxShadow',
          state: 'normal',
        },
      },
    ],
  },
  {
    controls: [
      {
        label: {
          en: 'Position',
        },
        setting: {
          id: 'pos',
        },
        options: {
          labelVariant: 'primary',
        },
        searchKeyword: 'position',
      },
      {
        conditionDisplay: 'pos && pos !== "static"',
        options: {
          hiddenSwitchDeviceIcon: true,
        },
        label: {
          en: 'Top',
        },
        setting: {
          id: 'top',
        },
        searchKeyword: 'position',
      },
      {
        conditionDisplay: 'pos && pos !== "static"',
        options: {
          hiddenSwitchDeviceIcon: true,
        },
        label: {
          en: 'Right',
        },
        setting: {
          id: 'right',
        },
        searchKeyword: 'position',
      },
      {
        conditionDisplay: 'pos && pos !== "static"',
        options: {
          hiddenSwitchDeviceIcon: true,
        },
        label: {
          en: 'Bottom',
        },
        setting: {
          id: 'bottom',
        },
        searchKeyword: 'position',
      },
      {
        conditionDisplay: 'pos && pos !== "static"',
        options: {
          hiddenSwitchDeviceIcon: true,
        },
        label: {
          en: 'Left',
        },
        setting: {
          id: 'left',
        },
        searchKeyword: 'position',
      },
      {
        conditionDisplay: 'pos && pos !== "static"',
        options: {
          hiddenSwitchDeviceIcon: true,
        },
        label: {
          en: 'Z-Index',
        },
        setting: {
          id: 'z',
        },
        searchKeyword: 'position',
      },
    ],
  },
  {
    controls: [
      {
        label: {
          en: 'Opacity',
        },
        setting: {
          id: 'op',
        },
        options: {
          labelVariant: 'primary',
        },
      },
    ],
  },
  {
    options: {
      displayNone: true,
    },
    controls: [
      {
        setting: {
          id: 'editorData',
        },
        layout: 'vertical',
      },
    ],
  },
  {
    controls: [
      {
        label: {
          en: 'Animation',
        },
        options: {
          nearestSupportedPlan: 'build',
          lockedOnPlans: ['trial', 'trial2022'],
          labelVariant: 'primary',
          labelInsideControl: true,
        },
        layout: 'vertical',
        setting: {
          id: 'animation',
        },
        conditionDisplay: `pageType !== "STATIC"`,
      },
    ],
  },
  {
    label: {
      en: 'CSS class',
    },
    help: {
      content: `A CSS class is a reusable styling for HTML elements. It helps to maintain a consistent look for those elements across your website. `,
    },
    controls: [
      {
        layout: 'vertical',
        setting: {
          id: 'cssClass',
        },
      },
    ],
  },
];
